import React from 'react';
import EmailForm from '../components/EmailForm';
import PartnersSection from '../components/Partners';
const Home = () => {

    return (
        <div className = "home-container">
            <div className="home-section">
                <div className="home-text">
                    <h1>Family FunsFeed</h1>
                    <p>Let’s Turn Family Time Into Fun Time</p>
                </div>
                <div className="home-logo">
                    <img src="Logo1.png" alt="Family FunsFeed Logo" />
                </div>
            </div>


            <div className="mission-section">
        <div>
          <h2>Our Mission</h2>
          <p>
            At Family FunsFeed (FFF), our mission is to create a safe and loving space for families to share joy, 
            build lasting memories, and strengthen their bonds, making family time truly special.
          </p>
        </div>
      </div>
      
      <div className="coming-soon-section">
        <div className="coming-soon-left">
          Family FunsFeed app coming soon on iOS and Android!
        </div>
        <div className="coming-soon-right">
                <EmailForm></EmailForm>
        </div>
      </div>

      <PartnersSection></PartnersSection>
      
        </div>
    );
};

export default Home;
